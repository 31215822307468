const K_WIDTH = 18
const K_HEIGHT = 18
const FONT_SIZE = 10

const MarkerStyle = {
	// initially any map object has left top corner at lat lng coordinates
	// it's on you to set object origin to 0,0 coordinates
	position: "absolute",
	width: K_WIDTH,
	height: K_HEIGHT,
	left: -K_WIDTH / 2,
	top: -K_HEIGHT / 2,

	border: "5px solid #f44336",
	borderRadius: K_HEIGHT,
	backgroundColor: "white",
	textAlign: "center",
	color: "#000000",
	fontSize: FONT_SIZE,
	fontWeight: "bold",
	padding: 5,
}

export { MarkerStyle }
