import React from "react"
import GoogleMapReact from "google-map-react"
import Marker from "./Marker"

const MapsComponent = ({ places }) => {
	const markers = places.map(
		(
			{
				//place_id,
				//formatted_address,
				text,
				geometry: {
					location: { lat, lng },
				},
			},
			index
		) => <Marker key={index} lat={lat()} lng={lng()} text={text} />
	)

	return (
		<GoogleMapReact
			bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_APIKEY }}
			defaultCenter={{
				lat: 46.85,
				lng: 2.35,
			}}
			defaultZoom={5}
		>
			{markers}
		</GoogleMapReact>
	)
}

export default MapsComponent
