import axios from "axios"

export const addRecord = async (formData) => {
	return await axios.post(`${process.env.REACT_APP_BACK_URL}/record`, formData)
}

export const addPrescription = async (formData) => {
	return await axios.post(`${process.env.REACT_APP_BACK_URL}/prescription-only`, formData)
}

export const filterPrescribers = async (inputValue) => {
	return (await axios.get(`${process.env.REACT_APP_BACK_URL}/prescribers/${inputValue}`)).data.prescribers
}
