import React from "react"
import { MarkerStyle } from "./Marker_styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouse, faShippingFast } from "@fortawesome/free-solid-svg-icons"

export default function Marker({ text }) {
	let textComponent
	if (text === "Home") {
		textComponent = <FontAwesomeIcon icon={faHouse} />
	} else if (text === "Shipping") {
		textComponent = <FontAwesomeIcon icon={faShippingFast} />
	} else {
		textComponent = text
	}
	return <div style={MarkerStyle}>{textComponent}</div>
}
