import React, { useState, useEffect } from "react"
import { useWatch } from "react-hook-form"
import MapsComponent from "../Maps"
import { getPlace } from "../../services/googleApi"

const MapsContainer = ({ control }) => {
	const [address, shipping_address] = useWatch({
		control,
		name: ["address", "shipping_address"],
		defaultValue: [],
	})
	const [places, setPlaces] = useState([])
	useEffect(() => {
		const fetchPlaces = async (address, shipping_address) => {
			let places = []
			try {
				if (address) {
					places.push(await getPlace(address, "Home"))
				}
				if (shipping_address) {
					places.push(await getPlace(shipping_address, "Shipping"))
				}
				return places
			} catch (err) {
				throw err
			}
		}
		fetchPlaces(address, shipping_address).then((places) => {
			setPlaces(places)
		})
	}, [address, shipping_address])

	return <div style={{ height: "200px", width: "87%" }}>{<MapsComponent places={places} />}</div>
}

const FileContainer = ({ field, control, defaultText, error }) => {
	const file = useWatch({
		control,
		name: field,
		defaultValue: [],
	})
	return (
		<div>{file.length ? file[0]["name"] : error || defaultText || "Veuillez joindre l'ordonnance si nécessaire"}</div>
	)
}

const AcceptContainer = ({ control }) => {
	const [firstname, lastname] = useWatch({
		control,
		name: ["firstname", "lastname", "accept"],
		defaultValue: ["[Prénom]", "[Nom]"],
	})
	return (
		<div className="accept-bulletin">
			<p>
				Je soussigné(e){" "}
				<span className="bold ">
					{firstname} {lastname}{" "}
				</span>
				atteste en cochant la case ci-avant : <br />
				avoir été informé(e) du libre choix de prestataire (pharmacie, magasin spécialisé...) pour la fourniture de
				l'attelle ou orthèse prescrite, <br />
				et choisis de me faire équiper par la société MEDICORTHO, situé au 155 Av François Godin 62780 Cucq
			</p>
		</div>
	)
}

export { MapsContainer, AcceptContainer, FileContainer }
