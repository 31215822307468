import React from "react"
import Tooltip from "./Tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons"
import { FileContainer } from "./live_components"

const InputFactory =
	(register, errors) =>
	(
		type,
		name,
		placeholder,
		tip,
		label,
		classNameContainer = "input-container",
		classNameInput = "input-field",
		classNameInputError = "input-field errorClass"
	) => {
		let labelComponent
		if (type === "date") {
			labelComponent = (
				<label htmlFor={name}>
					{label}
					<input
						type="text"
						id={name}
						onFocus={(e) => {
							e.target.type = "date"
						}}
						className={errors?.[name] ? classNameInputError : classNameInput}
						placeholder={placeholder}
						{...register(name)}
					/>
				</label>
			)
		} else {
			labelComponent = (
				<label htmlFor={name}>
					{label}
					<input
						type={type}
						id={name}
						className={errors?.[name] ? classNameInputError : classNameInput}
						placeholder={placeholder}
						{...register(name)}
					/>
				</label>
			)
		}
		return (
			<div className={classNameContainer}>
				<div className="flex">
					{labelComponent}
					<Tooltip tip={tip} name={name} />
				</div>

				{errors?.[name]?.message}
			</div>
		)
	}

const InputFileFactory =
	(register, control, errors) =>
	(
		name,
		tip,
		label,
		classNameContainer = "input-container",
		classNameInput = "input-file",
		classNameInputError = "input-file error"
	) => {
		return (
			<div className={classNameContainer}>
				<div className="flex">
					<label htmlFor={name} className={errors?.[name] ? classNameInputError : classNameInput}>
						<FileContainer field={name} control={control} defaultText={label} error={errors?.[name]?.message} />
						<FontAwesomeIcon icon={faCloudUpload} className="upload-icon" size="4x" />
						<input type="file" id={name} {...register(name)} />
					</label>
					<Tooltip tip={tip} name={name} />
				</div>
			</div>
		)
	}

export { InputFactory, InputFileFactory }
