import React from "react"
import { Link } from "react-router-dom"

import medicorthologo from "../img/medicorthologo.png"

const Footer = ({ route, text }) => (
	<div className="footer">
		<img className="img-logo-footer" src={medicorthologo} alt="medicortho-logo"></img>
		<a href="https://www.medicortho.fr/" target="_blank" rel="noreferrer noopener">
			<div className="title-footer">MEDICORTHO </div>
		</a>
		<div className="texte-footer">155 Av François Godin 62780 Cucq</div>
		<div className="texte-footer">Tél : 03 21 09 25 96</div>
		<div className="space"></div>

		{text && (
			<Link to={route} className="texte-footer">
				{text}
			</Link>
		)}
		<a href="https://www.medicortho.fr/mentions-legales/" target="_blank" rel="noreferrer noopener">
			<div className="texte-footer">Mentions Légales</div>
		</a>
		{
			<a href="https://www.medicortho.fr/politique-confidentialite/ " target="_blank" rel="noreferrer noopener">
				<div className="texte-footer">Politique de Confidentialité</div>
			</a>
		}
		<a href="https://www.medicortho.fr/qui-sommes-nous/" target="_blank" rel="noreferrer noopener">
			<div className="texte-footer">Notre entreprise</div>
		</a>

		{/* <a href="https://www.medicortho.fr/prise-en-charge/" target="_blank" rel="noreferrer noopener">
			<div className="texte-footer">Prise en charge</div>
		</a>
		<a href="https://www.medicortho.fr/politique-de-confidentialite/" target="_blank" rel="noreferrer noopener">
			<div className="texte-footer">Politique de confidentialité</div>
		</a>
		<a href="https://www.medicortho.fr/politique-de-confidentialite/" target="_blank" rel="noreferrer noopener">
			<div className="texte-footer">Confidentialité des données</div>
		</a> */}

		<div className="space"></div>

		<div className="texte-footer-copyright">©2022 MEDICORTHO - Tous droits réservés.</div>
	</div>
)

const Header = () => (
	<div className="header">
		<img className="img-logo" src={medicorthologo} alt="medicortho-logo"></img>
	</div>
)

export { Header, Footer }
