import { geocodeByPlaceId } from "react-google-places-autocomplete"

const formatAddress = async (google_address_object) => {
	const {
		place_id,
		structured_formatting: { main_text, secondary_text },
	} = google_address_object.value
	try {
		const [place] = await geocodeByPlaceId(place_id)
		const { long_name: postalCode = "" } = place.address_components.find((c) => c.types.includes("postal_code")) || {}
		return [main_text, secondary_text, postalCode]
	} catch (err) {
		throw err
	}
}

const getPlace = async (google_address_object, text) => {
	const { place_id } = google_address_object.value
	try {
		const [place] = await geocodeByPlaceId(place_id)
		return { ...place, ...{ text } }
	} catch (err) {
		throw err
	}
}

export { formatAddress, getPlace }
