import { formatAddress } from "../../services/googleApi"
import { addRecord } from "../../services/api"

const onSubmitFactory = (navigate, setIsSubmitting, setError) => async (data) => {
	try {
		console.log("I am trying to submit")
		setIsSubmitting(true)
		let billing_address, billing_city, billing_postalcode

		if (data.address) {
			;[billing_address, billing_city, billing_postalcode] = await formatAddress(data.address)
		} else {
			billing_address = data.manual_billing_address
			billing_city = data.manual_billing_city
			billing_postalcode = data.manual_billing_postal_code
		}

		// Validate that we have all parts of the billing address
		if (!billing_address || !billing_city || !billing_postalcode) {
			throw new Error("Incomplete billing address")
		}
		const formData = new FormData()
		formData.append("firstname", data.firstname)
		formData.append("lastname", data.lastname)
		formData.append("birth", data.birth)
		formData.append("email", data.email)
		formData.append("phone", data.phone)
		formData.append("address", billing_address)
		formData.append("city", billing_city)
		formData.append("postalcode", billing_postalcode)
		formData.append("social_security", data.social_security)
		formData.append("amc", data.amc)
		formData.append("accept", data.accept)
		formData.append("no_order", true)
		data.prescriber && formData.append("prescriber", data.prescriber._id)
		data.prescription[0] && formData.append("prescription", data.prescription[0])
		console.log("a")
		data.prescription_bis && data.prescription_bis[0] && formData.append("prescription_bis", data.prescription_bis[0])
		console.log("b")
		data.prescription_ter && data.prescription_ter[0] && formData.append("prescription_ter", data.prescription_ter[0])
		console.log("c")
		data.mutual_photo[0] && formData.append("mutual_photo", data.mutual_photo[0])
		await addRecord(formData)
		navigate("/success")
	} catch (err) {
		console.log(err)
		setIsSubmitting(false)
		if (err.response && err.response.data && err.response.data.cause === "Prescription is missing") {
			setError("prescription", {
				type: "custom",
				message: "Si vous ne parvenez pas à joindre le fichier, basculez sur le navigateur Google Chrome",
			})
		} else if (err.message === "address") {
			setError("address", { type: "custom", message: "On attend une adresse postale" })
		} else {
			alert(
				"La commande a échoué pour des raisons inconnues. Veuillez contacter le support MEDICORTHO par mail à commande@medicortho.fr ou par téléphone au 03 21 09 25 96"
			)
		}
	}
}

export default onSubmitFactory
