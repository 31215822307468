import React, { useState } from "react"
import "../Themes.css"

import { Header, Footer } from "../../components/Sections"
import { MapsContainer, AcceptContainer } from "../../components/RHFComponents/live_components"
import onSubmitFactory from "./onSubmit"
import {
	InputFactory,
	InputFileFactory,
	GoogleAutoCompleteFactory,
	SelectAutoCompleteFactory,
	SSNControllerFactory,
	PhoneControllerFactory,
} from "../../components/RHFComponents"
import { filterPrescribers } from "../../services/api"

import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"

import schema from "../../schemas/record"
import { joiResolver } from "@hookform/resolvers/joi"
import flyerPDF from "../../img/flyer prise de mesure_BAS.pdf"
import flyerPNG from "../../img/Flyer_Screen.png"
export default function Form() {
	const navigate = useNavigate()
	const [isSubmitting, setIsSubmitting] = useState(false)

	const {
		control,
		register,
		handleSubmit,
		setError,
		watch,
		formState: { errors },
	} = useForm({
		resolver: joiResolver(schema) /* mode: "onBlur" */,
		// defaultValues: {
		// 	firstname: "John",
		// 	lastname: "Doe",
		// 	birth: "1990-01-01",
		// 	email: "karcenty.pro@gmail.com",
		// 	phone: "0634567890",
		// 	toggle_manual_shipping_address: true,
		// 	manual_shipping_address: "123 Main St",
		// 	manual_shipping_postal_code: "12345",
		// 	manual_shipping_city: "City",
		// 	social_security: "190017510943402",
		// 	amc: "987654321",
		// 	mutual_photo: null,
		// 	prescriber: null,
		// 	prescription: null,
		// 	needMeasurements: "false",
		// 	accept: true,
		// },
	}) // to validate each time we leave a field.
	const Input = InputFactory(register, errors)
	const InputFile = InputFileFactory(register, control, errors)
	const GoogleAutoComplete = GoogleAutoCompleteFactory(control)
	const SelectAutoComplete = SelectAutoCompleteFactory(control)
	const SSNInput = SSNControllerFactory(control)
	const PhoneInput = PhoneControllerFactory(control)
	const onSubmit = onSubmitFactory(navigate, setIsSubmitting, setError)
	const {
		toggle,
		toggle_manual_shipping_address,
		toggle_manual_billing_address,
		needMeasurements,
		needShoeSize,
		prescription,
		prescription_bis,
		toggle_prescription_bis,
		toggle_prescription_ter,
	} = watch()

	console.log(errors)
	return (
		<div>
			<Header />
			<div className="title-formula">Formulaire de commande</div>
			<div className="under-title-formula">
				Commandez votre attelle prise en charge par la Sécurité Sociale et votre mutuelle en remplissant le formulaire
				ci-dessous.
			</div>

			<form className="form-big" onSubmit={handleSubmit(onSubmit)}>
				{Input("text", "firstname", "Prénom")}
				{Input("text", "lastname", "Nom")}
				{Input("date", "birth", "Date de Naissance", "Clicker sur la flèche pour ouvrir le calendrier")}
				{Input("email", "email", "Email")}
				{PhoneInput("phone", "Téléphone portable")}
				{!toggle_manual_shipping_address && (
					<>
						{GoogleAutoComplete(
							"shipping_address",
							"Adresse de livraison",
							"Si votre adresse est introuvable, vous pouvez la remplir manuellement"
						)}
						<div>
							<label className="address-note">
								Votre adresse de livraison est introuvable ?
								<input type="checkbox" {...register("toggle_manual_shipping_address")} />
							</label>
						</div>
					</>
				)}
				{toggle_manual_shipping_address && (
					<div>
						<div className="address-note">Veuillez rentrer votre addresse de livraison manuellement</div>
						{Input("text", "manual_shipping_address", "Adresse Livraison")}
						{Input("text", "manual_shipping_postal_code", "Code Postal")}
						{Input("text", "manual_shipping_city", "Ville ")}
					</div>
				)}
				<div
					className="address-note"
					style={{
						display: toggle ? "none" : "block", // toggle the visbility of an input
					}}
				>
					<div>Votre adresse de facturation est différente ?</div>
					{/* <input type="checkbox" name="toggle" {...register("toggle")} /> */}
					<label>
						Oui
						<input type="radio" value={"true"} {...register("toggle")} />
					</label>
					<label>
						Non
						<input type="radio" value={"false"} {...register("toggle")} />
					</label>
				</div>
				{toggle === "true" && (
					<>
						{!toggle_manual_billing_address && (
							<>
								{GoogleAutoComplete(
									"address",
									"Adresse de facturation",
									"Si votre adresse est introuvable, vous pouvez la remplir manuellement"
								)}
								<div>
									<label className="prescription-note">
										Votre adresse de facturation est introuvable ?
										<input type="checkbox" {...register("toggle_manual_billing_address")} />
									</label>
								</div>
							</>
						)}
						{toggle_manual_billing_address && (
							<div>
								<div className="prescription-note">Veuillez rentrer votre adresse de facturation manuellement</div>
								{Input("text", "manual_billing_address", "Adresse Facturation")}
								{Input("text", "manual_billing_postal_code", "Code Postal")}
								{Input("text", "manual_billing_city", "Ville")}
							</div>
						)}
					</>
				)}
				<div className="arange-map">
					<MapsContainer control={control} />
				</div>
				{SSNInput("social_security", "Numéro de Sécurité Sociale")}
				{Input(
					"number",
					"amc",
					"Numéro AMC ou de télétransmission",
					"Le numéro AMC parfois appelé numéro de télétransmission est le numéro d’identification de votre mutuelle. Vous pouvez l'obtenir sur votre carte de mutuelle et sur votre compte Ameli.fr"
				)}
				{InputFile(
					"mutual_photo",
					"Vous pouvez prendre en photo et/ou deposer votre carte mutuelle, avec le nom de la mutuelle et le numéro AMC (ou le numéro de télétransmission) bien visible",
					"Photo de la carte mutuelle"
				)}
				{/*Input(
					"text",
					"mutual",
					"Mutuelle",
					"Le nom de votre Mutuelle Santé est située sur votre carte de mutuelle et sur votre compte Ameli.fr dans la rubrique 'complémentaire santé'"
				)} */}
				{SelectAutoComplete(
					"prescriber",
					filterPrescribers,
					"Prescripteur",
					"Ici vous rentrez le nom du médecin ayant délivré votre ordonnance. Si il n'est pas dans les options, ne rentrez rien."
				)}
				{/* {Input(
					"text",
					"size",
					"Si possible, précisez la taille de l'attelle",
					"Vous pouvez ici donner votre pointure (42) ou alors la taille de l'attelle (S,M,L)"
				)} */}
				{Input(
					"date",
					"surgery_date",
					"En cas d’intervention, précisez la date",
					"Clicker sur la flèche pour ouvrir le calendrier"
				)}
				<div className="prescription-note">
					Veuillez surligner le produit que vous souhaitez commander sur l'ordonnance avant de la déposer.
				</div>
				{InputFile(
					"prescription",
					"Vous pouvez prendre en photo et/ou deposer votre ordonnance. Important : si vous ne parvenez pas à joindre le fichier, basculez sur le navigateur Google Chrome",
					"Ordonnance"
				)}
				{prescription && prescription.length > 0 && (
					<div
						className="text_toggle"
						style={{
							display: toggle_prescription_bis ? "none" : "block", // toggle the visbility of an input
						}}
					>
						Voulez-vous déposer une deuxième ordonnance ?
						<input type="checkbox" name="toggle_prescription_bis" {...register("toggle_prescription_bis")} />
					</div>
				)}
				{toggle_prescription_bis &&
					InputFile(
						"prescription_bis",
						"Vous pouvez prendre en photo et/ou deposer votre ordonnance. Important : si vous ne parvenez pas à joindre le fichier, basculez sur le navigateur Google Chrome",
						"Ordonnance"
					)}
				{prescription_bis && prescription_bis.length > 0 && (
					<div
						className="text_toggle"
						style={{
							display: toggle_prescription_ter ? "none" : "block", // toggle the visbility of an input
						}}
					>
						Voulez-vous déposer une troisième ordonnance ?
						<input type="checkbox" name="toggle_prescription_ter" {...register("toggle_prescription_ter")} />
					</div>
				)}
				{toggle_prescription_ter &&
					InputFile(
						"prescription_ter",
						"Vous pouvez prendre en photo et/ou deposer votre ordonnance. Important : si vous ne parvenez pas à joindre le fichier, basculez sur le navigateur Google Chrome",
						"Ordonnance"
					)}
				<div className="text_toggle">
					<div className="prescription-note">Avez-vous besoin de bas de contentions ? </div>
					<label>
						Oui
						<input type="radio" value={"true"} {...register("needMeasurements")} />
					</label>
					<label>
						Non
						<input type="radio" value={"false"} {...register("needMeasurements")} />
					</label>
				</div>
				{needMeasurements === "true" && (
					<>
						<div className="measurement-guide">
							BAS DE CONTENTION: <br /> GUIDE DE PRISE DE MESURES
						</div>
						<div className="measurement-image">
							<img src={flyerPNG} alt="Diagram for measurements" />
							<div style={{ marginTop: 10 }}>
								<a href={flyerPDF} target="_blank" rel="noopener noreferrer" className="info-link">
									En savoir plus (Guide PDF Complet)
								</a>
							</div>
						</div>
						<div className="measurement-guide"></div>
						<div className="prescription-note">Rentrez vos 3 mesures grâce aux instructions ci-dessus</div>
						<div className="measurement-fields">
							{Input("number", "mesure_ankle", "Tour de cheville (cm)", "Tour de cheville à l'endroit le plus fin")}
							{Input("number", "mesure_thigh", "Tour de cuisse (cm)", "Tour de cuisse à l'endroit le plus fort")}
							{Input("number", "mesure_leg", "Hauteur de jambe (cm)", "Hauteur de jambe du sol au tour de cuisse")}
						</div>
					</>
				)}
				<div style={{ marginTop: "10px" }} />
				<div className="text_toggle">
					<div className="prescription-note">Avez-vous besoin de semelles / chaussures ? </div>
					<label>
						Oui
						<input type="radio" value={"true"} {...register("needShoeSize")} />
					</label>
					<label>
						Non
						<input type="radio" value={"false"} {...register("needShoeSize")} />
					</label>
				</div>
				{needShoeSize === "true" && (
					<>
						<div className="measurement-guide"></div>
						<div className="prescription-note">Rentrez votre pointure </div>
						{Input("number", "shoe_size", "Pointure", "Pointure FR (42, 43, 44...)")}
					</>
				)}
				<div className="accept-bulletin-flex">
					{Input("checkbox", "accept", "", "", "", "", "", "error")}
					<AcceptContainer control={control} />
				</div>
				<input className="button-submit-patient" type="submit" value="Envoyer" disabled={isSubmitting} />
				<div className="final-error"> {Object.keys(errors).length > 0 && "Vérifiez les champs requis"}</div>
			</form>
			<Footer route="/urgences" text="Je suis déjà équipé" />
		</div>
	)
}
