import { Link } from "react-router-dom"
import "./Themes.css"
import picto from "../img/picto.png"
import medicorthologo from "../img/medicorthologo.png"

export default function Success() {
	return (
		<div>
			<div className="header">
				<img className="img-logo" src={medicorthologo} alt="orthomedicortho-logo"></img>
			</div>
			<div className="div-central">
				<div className="title-formula">Votre formulaire a bien été soumis !</div>
				<div className="under-title-formula">
					Vous allez recevoir une confirmation par mail, veuillez vérifier vos courriers indésirables.
				</div>
				<img className="img-picto" src={picto} alt="success"></img>
			</div>
			<div className="footer">
				<Link to="/" className="texte-footer">
					Espace Patient
				</Link>
				{/* <Link to="/pro" className="texte-footer">
					Espace Professionel
				</Link> */}
			</div>
		</div>
	)
}
