import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"

export default function Tooltip({ tip, name }) {
	if (tip) {
		return (
			<div>
				<FontAwesomeIcon data-for={name} data-tip={tip} icon={faCircleInfo} className="info" />
				<ReactTooltip id={name} place="left" className="tooltip" />
			</div>
		)
	} else return <div />
}
