import React from "react"
import { useNavigate } from "react-router-dom"
import { Footer } from "../../components/Sections"
export default function Home() {
	const navigate = useNavigate()

	const handleResponse = (isEquipped) => {
		if (isEquipped) {
			navigate("/urgences")
		} else {
			navigate("/commande")
		}
	}

	return (
		<div>
			<div className="simple-form-container">
				<div className="simple-form-buttons">
					<button className="simple-form-button" onClick={() => handleResponse(true)}>
						1 - Je viens d’être équipé je donne mes infos
					</button>
					<button className="simple-form-button" onClick={() => handleResponse(false)}>
						2 - Je souhaite passer une commande
					</button>
				</div>
			</div>
			<Footer />
		</div>
	)
}
